
import {computed, defineComponent, ref} from 'vue'
import store from "@/store";
import {LoadPanel} from "@/core/composite/composite";
import {DOCUMENT_TYPES} from "@/core/config/Constant";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import DocumentIcon from "@/views/document/DocumentIcon.vue";
import Area from "@/components/base/Area.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Pagination from "@/components/base/paging/Pagination.vue";
import DocumentService from "@/core/services/DocumentService";
import Swal from "sweetalert2";
import FormRename from "@/views/document/FormRename.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import {IFilter} from "@/core/entity/IFilter";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import DocumentDecisionStatus from "@/views/claim/document/DocumentDecisionStatus.vue";

export default defineComponent({
  name: "DocumentList",
  components: {
    DocumentDecisionStatus,
    BaseSelect,
    BaseModal, FormRename,
    Pagination,
    QuickAction,
    DateTimeFormat,
    Area,
    DocumentIcon,
    Avatar,
    EntityLoading
  },
  props: {
    id: {required: true, type: String},
    area: {type: String, default: () => 'GENERIC'},
    actions: {type: String},
  },
  emits: ['showDetail', 'edit', 'createInvoice'],
  setup(props) {
    const filter = ref<IFilter>({
      paginationPage: 1,
      paginationSize: 20,
      key: {
        search: '', ep: 'createdBy',
        claimId: props.id,
        claimArea: props.area,
        noFilterPending: false,
        documentType: '',
        notType: 'AUDIO',
      },
    })

    const page = ref({
      loading: true, data: {
        total: 0,
        data: [],
        pages: 0,
      }
    })
    const handleUpdatePage = (event) => {
      filter.value.paginationPage = event;
      paginationLoad();

    };

    const paginationLoad = () => {
      console.log("load document")
      page.value.loading = true;
      DocumentService.findAll<any>(filter.value).then(res => {
        page.value = {loading: false, data: res}
      })
    }

    const access = computed(() => store.state.DocumentModule.access);
    const modelRename = ref({
      documentId: '', name: ''
    })
    const claim = computed(() => store.state.ClaimModule.claim)
    paginationLoad();
    const documentModel = ref({type: 'HCFA', docId: ''})
    return {
      claim,
      paginationLoad,
      handleUpdatePage,
      filter,
      modelRename,
      page,
      DOCUMENT_TYPES,
      ...LoadPanel(),
      access,
      documentModel,
    }
  },
  watch: {
    id(cb) {
      this.filter.key.claimId = cb
      this.paginationLoad();
    },
    area(a) {
      this.filter.key.claimArea = a
      this.paginationLoad();
    }
  },
  methods: {
    showDetail(id) {
      this.$emit('showDetail', id);
    },
    onDownloadPdf(id) {
      Swal.showLoading()
      DocumentService.download(id).then(res => {
        let fileName = res.headers["content-disposition"].split("filename=")[1];
        let blob = new Blob(
          [res.data]
        );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        Swal.close()
      })
    },
    rename(doc) {
      this.modelRename.documentId = doc.id
      this.modelRename.name = doc.name
      const baseModal = this.$refs.renameDocumentRef as typeof BaseModal
      baseModal.showBaseModal()
    },
    savedRename() {
      const baseModal = this.$refs.renameDocumentRef as typeof BaseModal
      baseModal.hideBaseModal()
      this.paginationLoad();
    },
    onEdit(doc) {
      this.$emit('edit', doc)
    },
    deletedDoc(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await DocumentService.delete(id).then(() => {
            this.paginationLoad();
          })
        }
      })
    },
    onFilter() {
      this.paginationLoad()
    },
    onCreate(type, docId) {
      this.$emit('createInvoice', {type: type, docId: docId})
    }
  }
})

