
import {defineComponent, ref} from 'vue'
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import BaseCustomForm from "@/views/custom-form/BaseCustomForm.vue";
import FormSchemaService from "@/core/services/FormSchemaService";
import FormService from "@/core/services/FormService";
import {LoadPanel} from "@/core/composite/composite";
import Swal from "sweetalert2";

export default defineComponent({
  name: "CustomForms",
  components: {BaseCustomForm, EntityLoading, BaseModal, QuickAction},
  props: {
    object: {type: Object, required: true},
    clientCode : {required: true},
    referenceCode :{}
  },
  setup(props) {
    const forms = ref<any>([])
    const loading = ref(true);

    const state = ref({
      modelName: 'New Confirmation of Claim',
      id: '',
      model: {schema: {}, data: {}, objectType: '', objId: ''},
      loading: true
    })
    const filter = ref({
      paginationPage: 1,
      paginationSize: 100,
      key: {objectId: props.object.objectId, objectType: props.object.objectType}
    })
    const load = () => {
      loading.value = true;
      FormService.findAll<any>(filter.value).then(res => {
        forms.value = res.data;
      }).finally(() => {
        loading.value = false;
      })
    }
    load();
    return {
      load,
      forms,
      state,
      ...LoadPanel(),
    }
  },
  methods: {
    onSaved() {
      const modal = this.$refs.modalCreateCustomFormRef as typeof BaseModal;
      modal.hideBaseModal();
      this.load();
    },
    onAdd(code) {
      this.state.loading = true;
      FormSchemaService.get<any>(code).then(res => {
        this.state.modelName = "New " + res.name;
        this.state.model.schema = res;
        FormService.prePopulate(this.object.objectId, this.object.objectType, res).then(res1 => {
          this.state.model.data = res1;
          this.state.id = '';
          this.state.model.objectType = this.object.objectType
          this.state.model.objId = this.object.objectId
          const modal = this.$refs.modalCreateCustomFormRef as typeof BaseModal;
          modal.showBaseModal();
        })
      }).finally(() => {
        this.state.loading = false;
      })

    },
    onEdit(id) {
      this.state.loading = true;
      FormService.get<any>(id).then(res => {
        this.state.modelName = 'Edit ' + res.schema.name
        this.state.model.schema = res.schema;
        this.state.model.data = res.data;
        this.state.id = res.id;
        this.state.model.objectType = this.object.objectType
        this.state.model.objId = this.object.objectId
      }).finally(() => {
        this.state.loading = false;
      })
      const modal = this.$refs.modalCreateCustomFormRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onDownloadPdf(id) {
      FormService.download(id).then(res => {
        let fileName = res.headers["content-disposition"].split("filename=")[1];
        let blob = new Blob(
          [res.data]
        );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await FormService.delete(id).then(() => {
            this.load();
          })
        }
      })
    }
  }
})
