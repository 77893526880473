
import {computed, defineComponent, onRenderTracked, onUpdated, ref} from 'vue'
import store from "@/store";
import DocumentList from "@/views/claim/document/DocumentList.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ClaimUploadForm from "@/views/claim/document/ClaimUploadForm.vue";
import CustomerDocumentStatus from "@/views/document/CustomerDocumentStatus.vue";
import FileShares from "@/views/fileshare/FileShares.vue";
import Audits from "@/views/audit/Audits.vue";
import Notes from "@/views/note/Notes.vue";
import {DocumentDetail, LoadPanel} from "@/core/composite/composite";
import {useToast} from "vue-toast-notification";
import DocumentNotifyForm from "@/views/document/DocumentNotifyForm.vue";
import FileShareForm from "@/views/fileshare/FileShareForm.vue";
import FormNote from "@/views/activity/FormNote.vue";
import DocumentConfirm from "@/views/document/DocumentConfirm.vue";
import ClaimDocStatus from "@/views/claim/adjudication/ClaimDocStatus.vue";
import DocumentClaimStatusForm from "@/views/claim/document/DocumentClaimStatusForm.vue";
import {Actions} from "@/store/enums/StoreEnums";
import CustomForms from "@/views/custom-form/CustomForms.vue";
import DocumentCreateInvoice from "@/views/invoice/DocumentCreateInvoice.vue";

export default defineComponent({
  name: "Documents",
  components: {
    DocumentCreateInvoice,
    CustomForms,
    DocumentClaimStatusForm,
    ClaimDocStatus,
    DocumentConfirm,
    FormNote,
    FileShareForm,
    DocumentNotifyForm,
    Notes, Audits, FileShares, CustomerDocumentStatus, ClaimUploadForm, BaseModal, QuickAction, DocumentList
  },
  props: {
    id: {required: true}
  },
  setup(props) {
    onUpdated(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    onRenderTracked(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })

    const expiredDate = new Date(new Date().setDate(new Date().getDate() + 3));
    const state = ref({
      documentId: '',
      document: {
        isPending: false,
        name: '',
        claimId: props.id,
        area: 'CUSTOMER',
        documentType: '',
        status: 'RECEIVED',
        description: '',
        notifies: [],
      }
    })
    const claim = computed(() => store.state.ClaimModule.claim);
    const access = computed(() => store.state.DocumentModule.access);
    const note = ref({text: '', documentId: ''})
    const status = ref('');
    const statusArea = ref('CUSTOMER');
    const documentModel = ref({type: '', docId: ''});
    return {
      status,
      documentModel,
      note,
      expiredDate,
      access,
      state,
      claim,
      ...DocumentDetail(),
      ...LoadPanel(),
      statusArea,
    }
  },
  methods: {
    buildName(area) {
      switch (area) {
        case 'GENERIC':
          return 'Other Documents'
        case 'CLAIM':
          return 'Provider Documents'
        case 'CUSTOMER':
          return 'Customer Documents'
      }
    },

    onUpload(area) {
      this.state.documentId = ''
      this.state.document = {
        isPending: false,
        name: '',
        claimId: this.id,
        area: area,
        documentType: 'LETTER',
        status: 'RECEIVED',
        notifies: [],
        description: ''
      }
      const modal = this.$refs.claimUploadDocumentRef as typeof BaseModal;
      modal.showBaseModal();
    },

    uploaded() {
      const modal = this.$refs.claimUploadDocumentRef as typeof BaseModal;
      modal.hideBaseModal();
      this.onLoad()
      if (this.documentDetail.id) {
        this.loadDocDetail(this.documentDetail.id);
      }
    },

    notify(documentId) {
      const baseModal = this.$refs.formNotifyDocumentRef as typeof BaseModal
      baseModal.showBaseModal()
      this.state.documentId = documentId
    },

    notified() {
      const baseModal = this.$refs.formNotifyDocumentRef as typeof BaseModal
      baseModal.hideBaseModal()
      useToast().success('Notify successfully', {position: 'top-right'})
    },
    onConfirm(doc) {
      this.note.documentId = doc.id;
      const modal = this.$refs.confirmDocumentRef as typeof BaseModal
      modal.showBaseModal();
    },
    onConfirmed() {
      const modal = this.$refs.confirmDocumentRef as typeof BaseModal
      modal.hideBaseModal();
      const list = this.$refs.documentList as typeof DocumentList;
      list.paginationLoad();
    },
    onSaveNote(res: any) {
      const baseModal = this.$refs.noteForm as typeof BaseModal
      baseModal.hideBaseModal()
      const notes = this.$refs.refNotes as typeof Notes
      notes.paginationLoad();
      this.loadDocDetail(res);
    },
    share() {
      const baseModal = this.$refs.addFileShareRef as typeof BaseModal
      baseModal.showBaseModal()
    },
    onEdit(doc) {
      this.state.documentId = doc.id
      this.state.document = {
        name: doc.name,
        claimId: this.id,
        area: doc.area,
        documentType: doc.documentType.code,
        status: doc.status.code,
        description: doc.description,
        isPending: doc.isPending,
        notifies: [],
      }
      const modal = this.$refs.claimUploadDocumentRef as typeof BaseModal;
      modal.showBaseModal();
    },
    shared() {
      const baseModal = this.$refs.addFileShareRef as typeof BaseModal
      baseModal.hideBaseModal()
      const files = this.$refs.refFileShares as typeof FileShares
      files.paginationLoad()
    },
    onUpdateStatus(area) {
      if (area === 'CUSTOMER') {
        this.status = this.claim.customerDocStatus
      }
      if (area === 'CLAIM') {
        this.status = this.claim.claimDocStatus
      }
      this.statusArea = area;
      const modal = this.$refs.claimDocStatusRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSaved() {
      store.dispatch(Actions.LOAD_CLAIM, this.claim.id);
      const modal = this.$refs.claimDocStatusRef as typeof BaseModal;
      modal.hideBaseModal();
    },
    onLoad() {
      const list = this.$refs.documentList as typeof DocumentList;
      list.paginationLoad();
      const list1 = this.$refs.claimDocumentList as typeof DocumentList;
      list1.paginationLoad();
      const list2 = this.$refs.genericDocumentList as typeof DocumentList;
      list2.paginationLoad();
    },
    onInvoiceCreate(props) {
      this.documentModel = props;
      const modal = this.$refs.documentNewInvoiceRef as typeof BaseModal
      modal.showBaseModal();
      const form = this.$refs.documentCreateInvoiceRef as typeof DocumentCreateInvoice;
      form.loadDocument(props.docId)
    },
    onShowMedicalRecord(id) {
      console.log(id);
    }
  }
})
