import { CURRENCY, FieldType, GroupField, LookupType } from "@/core/config/Constant";


export const HCFAFieldConfig = [
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Currency',
        type: FieldType.SELECT,
        key: 'currency',
        subKey: '',
        id: 'currency',
        subName: '',
        data: CURRENCY,
      },
      {
        col: 'col-md-6',
        name: 'Date Invoice Received',
        type: FieldType.DATETIME,
        key: 'receivedDate',
        subKey: '',
        id: 'currency',
        subName: '',
      },
    ]
  },
  {
    heading: '[1] Insurance Type',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-2',
        name: 'Medicare',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isMedicare',
        id: 'MEDICARE',
        subName: ''
      },
      {
        col: 'col-md-2',
        name: 'Medicaid',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isMedicaid',
        id: 'MEDICAID',
        subName: ''
      },
      {
        col: 'col-md-2',
        name: 'Tricare',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'iisTriCare',
        id: 'TRICARE',
        subName: ''
      },
      {
        col: 'col-md-2',
        name: 'Champva',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isChamPva',
        id: 'CHAMPVA',
        subName: ''
      },
      {
        col: 'col-md-2',
        name: 'Health Plan',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isGroupHealthPlan',
        id: 'HEALTHPLAN',
        subName: ''
      },
      {
        col: 'col-md-1',
        name: 'Fecablklung',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isFeCaBlkLung',
        id: 'FECABLKLUNG',
        subName: '(ID#)'
      },
      {
        col: 'col-md-2',
        name: 'Other',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isOtherMedicare',
        id: 'OTHER',
        subName: ''
      },
      {
        col: 'col-md-5',
        name: 'Insured\'s ID',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insuredNumber',
        id: 'insuredNumber',
        subName: ''
      }
    ]
  },
  {
    heading: '[9] Other Insured',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-4',
        name: 'Name',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'otherInsuredName',
        id: 'otherInsuredName',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: 'Policy or Group Number',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'otherInsuredPolicyOrGroupNumber',
        id: 'otherInsuredPolicyOrGroupNumber',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: 'Date of Birth',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'orderInsuredDob',
        id: 'orderInsuredDob',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: 'Gender',
        type: FieldType.RADIO,
        key: 'data',
        subKey: 'orderInsuredGender',
        id: 'orderInsuredGender',
        subName: '',
        data: [{value: 'M', name: 'M'}, {value: 'F', name: 'F'}]
      },
      {
        col: 'col-md-4',
        name: 'Employer or School',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'otherInsuredEmployerName',
        id: 'otherInsuredEmployerName',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: 'Insurance Plan',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insurancePlanNameOrProgramName',
        id: 'insurancePlanNameOrProgramName',
        subName: ''
      }
    ]
  },
  {
    heading: '[10] Is patient\'s condition related to',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-3',
        name: 'Employment?',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isEmployment',
        id: 'isEmployment',
        subName: ''
      },
      {
        col: 'col-md-3',
        name: 'Auto Accident?',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isAutoAccident',
        id: 'isAutoAccident',
        subName: ''
      },
      {
        col: 'col-md-3',
        name: 'Accident Place',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'autoAccidentPlace',
        id: 'autoAccidentPlace',
        subName: ''
      },
      {
        col: 'col-md-3',
        name: 'Other Accident?',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isOtherAccident',
        id: 'isOtherAccident',
        subName: ''
      }   ,
/*
      {
        col: 'col-md-4',
        name: '[10.d] Reserved for local use',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'reservedForLocalUse',
        id: 'reservedForLocalUse',
        subName: ''
      },
*/
    ]
  },
  {
    heading: '[11] Insured Info',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-3',
        name: 'Policy Group#',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insuredPolicyGroupOrFeCaNumber',
        id: 'insuredPolicyGroupOrFeCaNumber',
        subName: ''
      },
      {
        col: 'col-md-2',
        name: 'Gender',
        type: FieldType.RADIO,
        key: 'data',
        subKey: 'insuredPolicyGroupOrFeCaGender',
        id: 'insuredPolicyGroupOrFeCaGender',
        subName: '',
        data: [{value: 'M', name: 'M'}, {value: 'F', name: 'F'}]
      },
      {
        col: 'col-md-3',
        name: 'Employer or School',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insuredPolicyGroupOrFeCaEmployerName',
        id: 'insuredPolicyGroupOrFeCaEmployerName',
        subName: '',
      },
      {
        col: 'col-md-3',
        name: 'Insurance Plan',
        fullName: '11c. insurance plan name or program name',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insuredPolicyGroupFeCaPlanName',
        id: 'insuredPolicyGroupFeCaPlanName',
        subName: '',
      },
/*
      {
        col: 'col-md-4',
        name: '[11d] is there another health benefit plan?',
        fullName: '[11.d] is there another health benefit plan?',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isAnotherBenefitPlan',
        id: 'isAnotherBenefitPlan',
        subName: '',
      },
*/
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: '[14] Current Illness',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'Date:',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'illnessDt'
          },
          {
            col: 'col-md-6',
            name: 'Code:',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'illnessCode',
          }
        ]
      },
      {
        col: 'col-md-6',
        name: '[15] Similar Illness',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'Date:',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'illnessOtherDate'
          },
          {
            col: 'col-md-6',
            name: 'Code:',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'illnessOtherCode'
          }
        ]
      },
      {
        col: 'col-md-6',
        name: '[16] Patient Unable to Work',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'From:',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'disableDateFrom',
          },
          {
            col: 'col-md-6',
            name: 'To',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'disableDateTo'
          }
        ]
      },
      {
        col: 'col-md-6',
        name: '[18] Hospitalization:',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'From',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'hospitalDateFrom',
          },
          {
            col: 'col-md-6',
            name: 'To',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'hospitalDateTo',
          }
        ]
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-4',
        name: '[19] Additional Claim Information',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'additionClaimInformation',
      },
      {
        col: 'col-md-4',
        name: '[20] Outside Lab',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isOutSideLap',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: '[20] Charges',
        type: FieldType.NUMBER,
        key: 'data',
        subKey: 'labAmt',
        subName: ''
      }
    ]
  },

  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-12',
        name: '[21] Diagnosis or Nature of Illness or Injury',
        type: FieldType.INPUT_GROUP,
        groups: [
          {
            col: 'col-md-3',
            name: 'A',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag1',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'B',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag2',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'C',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag3',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'D',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag4',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'E',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag5',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'F',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag6',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'G',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag7',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'H',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag8',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'I',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag9',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'J',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag10',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'K',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag11',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'L',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag12',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
        ],
      }
    ],
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-4',
        name: '[22] Resubmission Code',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'resubmissionCode',
        id: 'resubmissionCode',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: '[22] Original Ref#',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'originalRefNo',
        id: 'originalRefNo',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: '[23] Prior Authorization #',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'priorAuthorizationNum',
        id: 'priorAuthorizationNum',
        subName: ''
      },

    ]
  },
  {
    heading: '',
    type: GroupField.LINES,
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-3',
        name: '[25] Federal Tax ID',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'federalTaxNum',
        id: 'federalTaxNum',
        subName: ''
      },
      {
        col: 'col-md-3',
        name: '[26] Patient Account No',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'patientAccNumber',
        id: 'patientAccNumber',
        subName: ''
      },
      {
        col: 'col-md-3',
        name: '[27] Accept Assignment?',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'acceptAssignment',
        id: 'acceptAssignment',
        subName: ''
      },
      {
        col: 'col-md-3',
        name: '[30] Balance Due',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'rsvdForNuccUse',
        id: 'rsvdForNuccUse',
        subName: ''
      },
    ]
  },
  {
    heading: '[31] Doctor',
    type: GroupField.PROVIDER,
    key: 'doctorId',
    subKey: '',
    service: false,
  },
  {
    heading: '[32] Service Facility',
    type: GroupField.PROVIDER,
    key: 'payToId',
    subKey: '',
    service: false,
  },
  {
    heading: '[33] Billing Provider',
    type: GroupField.PROVIDER,
    key: 'providerId',
    subKey: '',
    service: true,
  },
  {
    heading: '[17] Referring provider',
    type: GroupField.PROVIDER,
    key: 'referringId',
    service: false,
  },
]