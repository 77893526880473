
import {computed, defineComponent, ref, watch} from 'vue'
import store from "@/store";
import {InvoiceType} from "@/core/config/Constant";
import {HCFAFieldConfig} from "@/core/entity/HCFAFieldConfig";
import {ModelInvoiceHCFA} from "@/core/entity/IInvoiceHCFA";
import {HCFALine} from "@/core/entity/HCFALine";
import {UB04FieldConfig} from "@/core/entity/UB04FieldConfig";
import {ModelInvoiceUB} from "@/core/entity/IInvoiceUB04";
import {UB04Line} from "@/core/entity/UB04Line";
import {DentalFieldConfig} from "@/core/entity/DentalFieldConfig";
import {ModelInvoiceDental} from "@/core/entity/IInvoiceDental";
import {ModelDentalLine} from "@/core/entity/IDentalLine";
import {TripCanFieldConfig} from "@/core/entity/TripCanFieldConfig";
import {ModelTripCanInvoice} from "@/core/entity/ITripCanInvoice";
import {TripCanLine} from "@/core/entity/TripCanLine";
import {PrescriptionFieldConfig} from "@/core/entity/PrescriptionFieldConfig";
import {ModelPrescriptionDrugs} from "@/core/entity/IPrescriptionDrugs";
import {LostBaggageFieldConfig} from "@/core/entity/LostBaggageFieldConfig";
import {ModelLostBaggageInvoice} from "@/core/entity/ILostBaggageInvoice";
import {LostBagLine} from "@/core/entity/LostBagLine";
import {GenericFieldConfig} from "@/core/entity/GenericFieldConfig";
import {ModelInvoiceGeneric} from "@/core/entity/IInvoiceGeneric";
import {GenericLine} from "@/core/entity/IGenericLine";
import {StatementFieldConfig} from "@/core/entity/StatementFieldConfig";
import {ModelStatement} from "@/core/entity/IStatement";
import {StatementLine} from "@/core/entity/IStatementLine";
import {RXFieldConfig} from "@/core/entity/RXFieldConfig";
import {ModelRX} from "@/core/entity/IRX";
import {RXLine} from "@/core/entity/IRXLine";
import router from "@/router";
import InvoiceLabel from "@/views/invoice/InvoiceLabel.vue";
import BaseInvoiceForm from "@/views/invoice/BaseInvoiceForm.vue";
import DocumentService from "@/core/services/DocumentService";
import Row from "@/components/base/common/Row.vue";
import Translate from "@/views/translate/Translate.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";

export default defineComponent({
  name: "DocumentCreateInvoice",
  components: {EntityLoading, Translate, Row, BaseInvoiceForm, InvoiceLabel},
  props: {
    type: {type: String, required: true},
    docId: {type: String, required: false},
  },
  setup(props) {
    const document = ref({type: 'LOADING'});
    const loadDocument = async (id) => {
      document.value = {type: 'LOADING'};
      if (id) {
        await DocumentService.getUrl(id).then(res => {
          document.value = res;
        })
      } else {
        document.value = {type: 'LOADING'}
      }

    }
    const date = new Date().setHours(0, 0, 0, 0)
    const typeRef = ref(props.type);
    const claim = computed(() => store.state.ClaimModule.claim)
    const config = ref<any>();
    const data = ref({});
    const lines = ref<any>([]);
    const buildConfig = (type: string) => {
      lines.value = [];
      switch (type) {
        case InvoiceType.HCFA:
          config.value = HCFAFieldConfig;
          data.value = new ModelInvoiceHCFA();
          for (let i = 0; i < 6; i++) {
            lines.value.push(new HCFALine())
          }
          break
        case InvoiceType.UB04:
          config.value = UB04FieldConfig;
          data.value = new ModelInvoiceUB();
          for (let i = 0; i < 6; i++) {
            lines.value.push(new UB04Line())
          }
          break
        case InvoiceType.DENTAL:
          config.value = DentalFieldConfig;
          data.value = new ModelInvoiceDental();
          for (let i = 0; i < 3; i++) {
            lines.value.push(new ModelDentalLine())
          }
          break
        case InvoiceType.TRIP_CAN:
          config.value = TripCanFieldConfig
          data.value = new ModelTripCanInvoice();
          for (let i = 0; i < 6; i++) {
            lines.value.push(new TripCanLine())
          }
          break;
        case InvoiceType.PRESCRIPT:
          config.value = PrescriptionFieldConfig;
          data.value = new ModelPrescriptionDrugs()
          lines.value = [];
          break
        case InvoiceType.LOST_BAG:
          config.value = LostBaggageFieldConfig;
          data.value = new ModelLostBaggageInvoice();
          for (let i = 0; i < 6; i++) {
            lines.value.push(new LostBagLine())
          }
          break
        case 'GENERIC':
          config.value = GenericFieldConfig;
          data.value = new ModelInvoiceGeneric();
          for (let i = 0; i < 2; i++) {
            lines.value.push(new GenericLine())
          }
          break
        case InvoiceType.STATEMENT:
          config.value = StatementFieldConfig
          data.value = new ModelStatement();
          for (let i = 0; i < 2; i++) {
            lines.value.push(new StatementLine())
          }
          break
        case InvoiceType.RX:
          config.value = RXFieldConfig
          data.value = new ModelRX();
          for (let i = 0; i < 2; i++) {
            lines.value.push(new RXLine())
          }
          break
      }
    }
    buildConfig(props.type);
    watch(() => props.type, (cb: string) => {
      buildConfig(cb);
    })

    const onSavedInvoice = (invoice: any) => {
      router.push({
        name: 'claimInvoices',
        params: {id: invoice.claim?.id}
      })
    }
    const buildName = (type) => {
      switch (type) {
        case InvoiceType.STATEMENT:
          return 'Statement'
        case InvoiceType.UB04:
          return 'UB04';
        case InvoiceType.DENTAL:
          return 'Dental';
        case InvoiceType.HCFA:
          return 'HCFA';
        case InvoiceType.PRESCRIPT:
          return 'Prescription Old';
        case InvoiceType.TRIP_CAN:
          return 'Trip Cancellation';
        case InvoiceType.LOST_BAG:
          return 'Lost Baggage';
        case InvoiceType.RX:
          return 'Prescription';
        case 'GENERIC':
          return 'Generic';
      }
    }
    return {
      claim,
      onSavedInvoice,
      ModelInvoiceHCFA,
      ModelInvoiceUB,
      InvoiceType,
      buildName,
      config,
      data,
      typeRef,
      lines,
      document,
      date,
      loadDocument,
    }
  }
})
